import { LitElement, html, css, property, query, queryAll } from '/web_modules/lit-element.js';
import { installRouter } from '/web_modules/pwa-helpers/router.js';
import { classMap } from '/web_modules/lit-html/directives/class-map.js';
import { connect } from '/web_modules/pwa-helpers/connect-mixin.js';
import { store, RootState } from '../store.js';
import { FchSharedStyles } from '../styles/fch-shared-styles.js';
import { Member, memberSelector } from '../reducers/member.js';
import { Snackbar, Policies } from '../reducers/app.js';
import { updateLocation } from '../actions/app.js';
import { getEnvironment, makeRequest, Preferences } from '../common/helpers.js';
import Session from '../common/Session.js';
import * as Sentry from '/web_modules/@sentry/browser.js';
// import LogRocket from '/web_modules/logrocket.js';
import { fetchMemberPreferences } from '../actions/preference.js';
import './widgets/fch-snackbar.js';
import './widgets/text-input.js';
import './fch-404.js';
import './widgets/app-drawer.js';
import './widgets/app-bar.js';
import './widgets/app-menu.js';
import './widgets/fcd-fab.js';
import './widgets/fch-dialog.js';
import '../common/fch-icons.js';
import './widgets/save-story-widget.js';
import './widgets/fcd-news-ticker.js';
import './static/fcd-cookie-policy.js';
import { fetchMember } from '../actions/member.js';
import { Story, breakingStoriesSelector } from '../reducers/story.js';

class FchClient extends connect(store)(LitElement) {

  @property({type: String})
  page?: string;

  @property({type: Object})
  member?: Member;

  @property({type: Object})
  snackbar?: Snackbar;

  @property({ type: Boolean })
  isDrawerOpen = false;

  @property({ type: Object })
  policies?: Policies;

  @property({ type: Array })
  breakingStories: Story[] = [];

  @queryAll('app-drawer a')
  menuItems?: NodeListOf<HTMLLinkElement>;

  @queryAll('app-drawer a.private')
  privateMenus?: NodeListOf<HTMLLinkElement>;

  @query('app-menu')
  appMenu: any;

  @query('app-drawer')
  drawer: any;

  @query('.drawer-frame-root')
  drawerFrameRootEl: any;

  @query('main')
  mainEl: any;

  @query('.elements')
  elementsContainerEl: any;

  @query('#feedback-dialog')
  feedbackDialog: any;

  @query('app-drawer')
  appDrawer: any;

  @query('#feedback-subject')
  feedbackSubjectInp: any;

  @query('#feedback-desc')
  feedbackDescInp: any;

  @query('fcd-cookie-policy')
  cookiePolicyEl: any;

  @query('.consent-banner')
  consentBannerEl: any;

  // @query('.app-content footer')
  // footerEl: any;

  scrollPosition?: number;

  imgPlaceholder = '/assets/images/avatar-default.svg';

  // profileImagesContainer = 'https://fcdblobstorage.blob.core.windows.net/profile-images/';

  pageY = 0;

  static get styles() {
    return [
      FchSharedStyles,
      css`
        :host {
          display: block;
          --color-primary: #002e5a;
          --color-primary-dark: #001d3c;
          --color-primary-light: #9299a2;
          --color-secondary: #50ef88;
          --color-typography-primary: #424A55;
          --color-typography-light: #84919e;
          --app-bar-height: 70px;
        }
        .scroll-disabled {
          height: 100vh;
          overflow-y: hidden;
        }
        .app-content {
          width: 100%;
        }

        /** App bar */

        app-bar {
          height: var(--app-bar-height);
        }
        .top {
          position: sticky;
          top: 0;
          z-index: 1;
        }
        .drawer-toggle-btn {
          color: rgba(255, 255, 255, 0.8);
          cursor: pointer;
          height: 32px;
          width: 32px;
        }
        .drawer-toggle-btn:hover {
          color: rgba(255, 255, 255, 0.7);
        }
        .logo-container {
          transform: scale(0.85);
        }
        footer .logo-container img {
          width: 180px;
          height: 35px;
        }
        .signin-btn {
          padding: 10px 30px;
          transition: background-color .3s ease-out, color .3s ease-out;
          box-shadow: inset 0px 0px 0px 2px var(--color-secondary);
          border-radius: 50px;
        }
        .signin-btn:hover,
        .signin-btn:active {
          background-color: var(--color-secondary);
          color: var(--color-primary);
        }
        .signin-text {
          color: #fff;
        }
        /* .register-btn {
          padding: 0px;
          margin-right: 15px;
        } */
        .public-menu {
          display: flex;
        }
        .user-menu {
          display: flex;
        }
        .user-menu .user-details {
          align-items: center;
          color: var(--color-secondary);
          cursor: pointer;
          display: flex;
          height: 42px;
          margin-right: -5px;
          position: relative;
        }
        .user-menu .user-avatar {
          border-radius: 50%;
          height: 32px;
          width: 32px;
        }
        .user-name {
          display: none;
        }
        .user-menu [icon="chevron-down"] {
          color: var(--color-secondary);
          transform: scale(.8);
        }
        .visually-hidden {
          border: 0 !important;
          clip: rect(0, 0, 0, 0) !important;
          height: 1px !important;
          margin: -1px !important;
          overflow: hidden !important;
          padding: 0 !important;
          position: absolute !important;
          white-space: nowrap !important;
          width: 1px !important;
        }
        @media (min-width: 375px) {
          .drawer-toggle-btn {
            margin-right: 13px;
          }
          .logo-container {
            transform: unset;
          }
        }
        @media (min-width: 576px) {
          /* .signin-btn {
            padding: 10px 30px;
          } */
          /* .register-btn {
            opacity: .9;
            transition: opacity .3s ease-out;
          }
          .register-btn:hover {
            opacity: 1;
          } */
          .drawer-toggle-btn {
            margin-right: 30px;
          }
          .user-menu .user-avatar {
            height: 42px;
            width: 42px;
          }
          .user-menu [icon="chevron-down"] {
            margin-left: 8px;
            transform: scale(1);
          }
        }
        @media (min-width: 992px) {
          .user-name {
            display: block;
            font-size: 14px;
            font-weight: 500;
            margin-left: 20px;
          }
        }

        /** Drawer */

        app-drawer {
          height: calc(100vh - var(--app-bar-height));
        }

        /* Target devices in landscape mode */
        @media screen and (min-device-width: 320px) and (max-device-width: 1024px) and (orientation: landscape) {
          app-drawer {
            overflow-y: scroll;
          }
        }

        /** User account menu */

        app-menu {
          --list-top: 56px;
          --list-width: 140px;
        }
        app-menu a:hover {
          color: rgba(255, 255, 255, 0.8);
        }
        app-menu .user-info {
          margin-left: 8px;
          max-width: 226px;
        }
        app-menu #name,
        app-menu .email {
          display: block;
        }
        app-menu .email {
          font-size: 10px;
          color: #E7E7E7;
        }

        /** Main */

        main {
          /* padding-top: var(--app-bar-height); */
          position: relative;
        }
        .elements {
          position: relative;
        }
        .elements > * {
          display: none;
          opacity: 0;
        }
        .elements > .active {
          animation-duration: 1s;
          animation-fill-mode: forwards;
          animation-name: fadein;
          display: block;
          /** Active element height: (vh - appbar - footer) */
          min-height: calc(100vh - 70px - 210px);
        }
        fcd-fab iron-icon {
          color: var(--color-primary);
          margin: 10px;
          width: 35px;
          height: 35px;
        }
        #feedback-dialog textarea {
          height: 200px;
          width: 500px;
        }
        footer {
          align-items: center;
          background-color: #000;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 40px 0;
          width: 100%;
        }
        footer a {
          color: #999;
          display: inline-block;
          font-size: 12px;
          letter-spacing: 1px;
          margin: 20px 10px;
        }
        .consent-banner {
          -webkit-text-size-adjust: 100%;
          background-color: #171717;
          padding: 20px;
          position: -webkit-sticky;
          position: sticky;
          z-index: 2;
          bottom: 0;
          left: 0;
          right: 0;
        }
        .consent-banner p {
          color: #F9F9F9;
          font-size: 14px;
          line-height: 1.9;
          margin-top: 0;
        }
        .link-policy {
          color: currentColor;
          text-decoration: underline;
        }
        .consent-banner .link-policy:hover {
          color: #F9F9F9;
        }
        .consent-buttons {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
        }
        .accept-consent {
          background-color: var(--color-secondary);
          border-radius: 30px;
          color: #050505;
          font-size: 14px;
          font-weight: 400;
          padding: 14px;
          text-align: center;
          transition: opacity .3s ease-out;
        }
        .customize-consent {
          background-color: #E7E7E7;
          border-radius: 30px;
          color: #050505;
          display: inline-block;
          font-size: 14px;
          font-weight: 400;
          padding: 14px;
          text-align: center;
          transition: opacity .3s ease-out;
        }
        .consent-buttons .customize-consent:hover {
          color: #050505;
          opacity: 0.85;
        }
        footer .logo-container {
          text-align: center;
        }
        footer .links {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin: 20px 0;
        }
        footer .links a {
          margin: 0 10px;
        }
        .consent-banner p {
          text-align: center;
        }
        .consent-buttons {
          justify-content: center;
        }
        iron-icon[icon="exclamation-outline"] {
          color: #F9F9F9;
        }
        @keyframes fadein {
          from {opacity: 0;}
          to  {opacity: 1;}
        }
        @media(max-width: 587px) {
          #feedback-dialog textarea {
            width: 100%;
          }
        }
        @media print {
          * {
            -webkit-print-color-adjust: exact !important;
            color-adjust: exact !important;
          }
          .drawer-toggle-btn,
          footer {
            display: none !important;
          }
          .drawer-frame-root main {
            padding-top: 0 !important;
          }
        }
      `
    ];
  }

  render() {
    const {
      page = '',
      member,
      snackbar: {
        warning = '',
        message = '',
        type = 'default'
      } = {}
    } = this;

    const loginPath = `/login${window.location.pathname}`;

    return html`
      <fcd-fab @click="${() => this.showFeedbackForm()}">
        <iron-icon icon="chat"></iron-icon>
      </fcd-fab>

      <fch-dialog id="feedback-dialog" dTitle="Feedback">
        <label>Subject</label>
        <text-input type="text" id="feedback-subject"></text-input>
        <label>Description</label>
        <textarea id="feedback-desc"></textarea>
      </fch-dialog>

      <save-story-widget></save-story-widget>

      <div class="elements">
        <fch-login class="${classMap({'active': page === 'login'})}"></fch-login>
        <fcd-register class="${classMap({'active': page === 'register'})}"></fcd-register>
        <fcd-post-register class="${classMap({'active': page === 'post-register'})}"></fcd-post-register>
        <password-reset class="${classMap({'active': page === 'password-reset'})}"></password-reset>
        <fcd-suspended class="${classMap({'active': page === 'suspended'})}"></fcd-suspended>
      </div>

      <div class="drawer-frame-root" ?hidden="${['login', 'register', 'post-register', 'suspended'].includes(page)}">
        <div class="app-content">
          <div class="top">
            <app-bar role="navigation">
              <div slot="left">
                <button
                  class="drawer-toggle-btn"
                  @click="${this.toggleDrawer}"
                  aria-label="Toggle navigation"
                  aria-hidden="${!this.member}"
                  aria-expanded="${this.isDrawerOpen}"
                  aria-controls="navbar"
                  ?hidden="${!this.member}">
                    <iron-icon icon="${this.isDrawerOpen ? 'close' : 'menu'}"></iron-icon>
                </button>

                <a href="/" class="logo-container">
                  <img src="/assets/images/fcd-logo.svg" alt="Financial Crime Digest logo" loading="lazy"/>
                </a>
              </div>

              <div slot="right">
                <div class="public-menu" ?hidden="${!!member}">
                  <!-- <a href="/register" class="btn secondary register-btn">Register</a> -->
                  <a href="${loginPath}" class="btn btn-outline outline-secondary signin-btn">
                    <span class="signin-text">Sign In</span>
                  </a>
                </div>

                <div class="user-menu" ?hidden="${!member}">

                  <app-menu align-left>
                    <button class="user-details" slot="button">
                      <img
                        class="user-avatar"
                        src="${member?.LinkedinData ? member.LinkedinData.img_url : this.imgPlaceholder}"
                        alt="${member ? member.full_name : ''}"
                        @error="${this.handleProfileImageError}"
                      >
                      <span class="user-name">${member && member.full_name.split(' ')[0]}</span>
                      <iron-icon icon="chevron-down"></iron-icon>
                    </button>

                    <a slot="item" href="/account-settings">Settings</a>
                    <a slot="item" href="/organisation" ?hidden="${!!member && member.Role.name !== 'client'}">Organisation</a>
                    <a class="user-signout" slot="item" href="/" @click="${this.signOut}">Sign Out</a>
                  </app-menu>
                </div>
              </div>
            </app-bar>

            <fcd-news-ticker .stories="${this.breakingStories}" ?hidden="${!this.breakingStories.length}"></fcd-news-ticker>
          </div>

          <main>
            <app-drawer id="navbar" ?open="${this.isDrawerOpen}" @closed="${this.toggleDrawer}" aria-hidden="${!this.isDrawerOpen}">
              <a href="/" slot="nav-main" class="${classMap({'active': page === 'my-feed'})}">My Feed</a>
              <a href="/country-update-map" slot="nav-main" class="${classMap({'active': page === 'country-update-map'})}">Country updates</a>
              <a href="/fcd-monthly" slot="nav-main" class="public ${classMap({'active': page === 'fcd-monthly'})}">Monthly FCD contents</a>
              <a href="/top-stories" slot="nav-main" class="public ${classMap({'active': page === 'top-stories'})}">Top stories</a>
              <a href="/events" slot="nav-main" class="private ${classMap({'active': page === 'events'})}">Events</a>
              <a href="/feature-stories" slot="nav-main" class="${classMap({'active': page === 'feature-stories'})}">Special features</a>
              <a href="/power-search" slot="nav-main" class="private ${classMap({'active': page === 'power-search'})}">Power search</a>
              <!-- a href="/alerts" slot="nav-main" class="private $ {classMap({'active': page === 'alerts'})}">My alerts</a -->
              <a href="/reports" slot="nav-main" class="private ${classMap({'active': page === 'reports'})}">Reporting</a>
              <a href="/saved" slot="nav-main" class="private ${classMap({'active': page === 'saved'})}">Saved Stories</a>
              <a href="/read-stories" slot="nav-main" class="private ${classMap({'active': page === 'read-stories'})}">Recently Read</a>
              <a href="/account-settings" slot="nav-main" class="private ${classMap({'active': page === 'account-settings'})}">Account settings</a>
              <!-- a href="/alert" slot="nav-main" class="private $ {classMap({'active': page === 'alert'})}">Alerts</a -->
              <a slot="nav-main" class="sandbox-toggle">Sandbox</a>
              <!-- <a href="/risk-assessment" slot="sandbox" class="${classMap({'active': page === 'risk-assessment'})}">Risk Assessment (beta)</a> -->
              <a href="/case-studies" slot="sandbox" class="${classMap({'active': page === 'case-studies'})}">Case Studies (beta)</a>
              <a href="/regulator-focuses" slot="sandbox" class="${classMap({'active': page === 'regulator-focuses'})}">Regulator focus (beta)</a>
            </app-drawer>

            <div class="elements">
              <fcd-home
                class="${classMap({'active': page === 'home'})}"></fcd-home>
              <fcd-monthly
                class="${classMap({'active': page === 'fcd-monthly'})}"></fcd-monthly>
              <fcd-story
                class="${classMap({'active': page === 'story'})}"></fcd-story>
              <fcd-events
                class="${classMap({'active': page === 'events'})}"></fcd-events>
              <fcd-event
                class="${classMap({'active': page === 'event'})}"></fcd-event>
              <fcd-power-search
                class="${classMap({'active': page === 'power-search'})}"></fcd-power-search>
              <fcd-alerts
                class="${classMap({'active': page === 'alerts'})}"></fcd-alerts>
              <fcd-reports
                class="${classMap({'active': page === 'reports'})}"></fcd-reports>
              <fcd-report-editor
                class="${classMap({'active': page === 'report-editor'})}"></fcd-report-editor>
              <fcd-country-update-map
                class="${classMap({'active': page === 'country-update-map'})}"></fcd-country-update-map>
              <fcd-country-updates
                class="${classMap({'active': page === 'country-updates'})}"></fcd-country-updates>
              <fcd-country-profile
                class="${classMap({'active': page === 'country-profile'})}"></fcd-country-profile>
              <fcd-case-studies
                class="${classMap({'active': page === 'case-studies'})}"></fcd-case-studies>
              <fcd-case-study
                class="${classMap({'active': page === 'case-study'})}"></fcd-case-study>
              <fcd-regulator-focuses
                class="${classMap({'active': page === 'regulator-focuses'})}"></fcd-regulator-focuses>
              <fcd-regulator-focus
                class="${classMap({'active': page === 'regulator-focus'})}"></fcd-regulator-focus>
              <fcd-feature-stories
                class="${classMap({'active': page === 'feature-stories'})}"></fcd-feature-stories>
              <fcd-theme-stories
                class="${classMap({'active': page === 'theme-stories'})}"></fcd-theme-stories>
              <saved-stories
                class="${classMap({'active': page === 'saved'})}"></saved-stories>
              <fcd-top-stories
                class="${classMap({'active': page === 'top-stories'})}"></fcd-top-stories>
              <!-- <fcd-suggested-stories
                class="${classMap({'active': page === 'suggested-stories'})}"></fcd-suggested-stories> -->
              <!-- <fcd-related-stories
                class="${classMap({'active': page === 'related-stories'})}"></fcd-related-stories> -->
              <fcd-read-stories
                class="${classMap({'active': page === 'read-stories'})}"></fcd-read-stories>
              <fch-alert
                class="${classMap({'active': page === 'alert'})}"></fch-alert>
              <account-settings
                class="${classMap({'active': page === 'account-settings'})}"></account-settings>
              <fcd-organisation
                class="${classMap({'active': page === 'organisation'})}"></fcd-organisation>
              <fcd-about-aperio
                class="${classMap({'active': page === 'about-aperio'})}"></fcd-about-aperio>
              <fcd-my-feed
                class="${classMap({'active': page === 'my-feed'})}"></fcd-my-feed>
              <fcd-feed-editor
                class="${classMap({'active': page === 'feed-editor'})}"></fcd-feed-editor>
              <fcd-channel
                class="${classMap({'active': page === 'channel'})}"></fcd-channel>
              <fcd-risk-assessment
                class="${classMap({'active': page === 'risk-assessment'})}"></fcd-risk-assessment>
              <fcd-terms-conditions
                class="${classMap({ 'active': page === 'terms-conditions' })}"></fcd-terms-conditions>
              <fcd-cookie-policy
                class="${classMap({ 'active': page === 'cookie-policy' })}"></fcd-cookie-policy>
              <fcd-privacy-policy
                class="${classMap({ 'active': page === 'privacy-policy' })}"></fcd-privacy-policy>
              <fcd-accessibility-policy
                class="${classMap({ 'active': page === 'accessibility' })}"></fcd-accessibility-policy>
              <fch-snackbar
                class="${classMap({'warning': warning, [type]: true, 'opened': message})}">${message}</fch-snackbar>
            </div>
          </main>

          <footer ?hidden="${['my-feed', '', 'report-editor', 'country-update-map'].includes(page)}">
            <a href="https://www.aperio-intelligence.com" class="logo-container" target="_blank">
              <img src="/assets/images/aperio-logo.svg" alt="Financial Crime Digest logo" loading="lazy"/>
            </a>
            <div class="links">
              <a href="" hidden>CONTACT</a>
              <a href="" hidden>GDPR</a>
              <a href="/terms-conditions">TERMS &amp; CONDITIONS</a>
              <a href="/cookie-policy">COOKIE POLICY</a>
              <a href="/privacy-policy">PRIVACY POLICY</a>
              <a href="/accessibility">ACCESSIBILITY</a>
            </div>
          </footer>

          <div class="consent-banner" ?hidden="${Object.keys(this.policies as string[]).length > 1}" role="alert">
            <p>
              <iron-icon icon="exclamation-outline"></iron-icon>
              We use cookies to collect data to improve your experience on our website. By using our website, you agree to the collection of data as described in our <a class="link-policy" href="/privacy-policy">Privacy Policy</a> and our <a class="link-policy" href="/cookie-policy">Cookie Policy</a>.
            </p>
            <div class="consent-buttons">
              <button class="accept-consent" @click="${this.setCookieAccepted}">Accept all cookies</button>
              <a class="customize-consent" href="/cookie-policy#cookie-preferences">Customize settings</a>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  async firstUpdated() {
    const session = Session.get();
    await store.dispatch(fetchMember(session?.member?.id));
    const state = store.getState();
    const app = state.app;
    const env = getEnvironment().env;
    const member = memberSelector(state);

    if (env !== 'development') {
      Sentry.init({
        // debug: true,
        environment: env,
        release: `financial-crime-digest@${app.version}`,
        dsn: 'https://cc14c206c91d44db8e422208f13b5d06@sentry.io/1363248',
        integrations: [
          new Sentry.BrowserTracing(),
          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
          })
        ],
        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        beforeSend(event, _hint) {
          // Handle the SecurityError
          if (event.exception) {
            if (event.exception.values?.[0]?.type === 'SecurityError') {
              //TODO: handle SecurityError
            } else {
              Sentry.showReportDialog({ eventId: event.event_id, user: {
                name: member?.full_name,
                email: member?.email
              }});
            }
          }

          return event;
        }
      });

      //FIXME: this get's never called if the user just registrated only after the next reload!!
      if (member) {
        Sentry.configureScope((scope) => {
          scope.setUser({'userId': member.id});
        });

        // LogRocket.identify(String(member.id), {
        //   name: member.full_name,
        //   email: member.email
        // });
      }

      // LogRocket.init('9b3scc/fcd');
    } else {
      this.initHotReloading();
    }

    //TODO: check if this is still needed, the single place this was
    // used was the makeRequest function for getting the token, but
    // that now gets is vis `Session.get()`. We either remove this
    // or get the token from the store instead of the Session helper!!
    // store.dispatch(addSessionToRedux(session)).then(() => {
    //   installRouter(this._updateLocation.bind(this));
    // });

    installRouter(this._updateLocation.bind(this));
    store.dispatch(fetchMemberPreferences());    
  }

  stateChanged(state: RootState) {
    this.page = state.app.route[0];
    this.member = memberSelector(state);
    this.snackbar = state.app.snackbar;
    this.policies = state.app.policies;
    this.breakingStories = breakingStoriesSelector(state);

    this.filterMenus(this.member);
    this.showLinkedinFollowIfNecessary();
  }

  filterMenus(member?: Member) {
    this.menuItems?.forEach((link) => {
      if (link.classList.contains('private'))
        link.hidden = !member;
      if (link.classList.contains('public'))
        link.hidden = !!member;
    });
  }

  showFeedbackForm() {
    this.feedbackDialog.confirmDialog().then(async () => {
      const subject = this.feedbackSubjectInp.value;
      const description = this.feedbackDescInp.value;
      // const member = memberSelector(store.getState());

      if (this.member && subject && description) {
        const {email, full_name} = this.member;
        await makeRequest('/sec/send-feedback', {email, full_name, subject, description}, 'POST');
        this.feedbackSubjectInp.value = '';
        this.feedbackDescInp.value = '';
      }
    }).catch((e) => console.log(e));
  }

  async _updateLocation(location: Location) {
    store.dispatch(updateLocation(location));
    // temporarily, we scroll to top when URL changes
    // without this, the scroll position will be the same
    // on the next page as on the previous.
    window.scrollTo(0, 0);

    document.dispatchEvent(new CustomEvent('location-changed', {
      composed: true,
      bubbles: true,
      detail: location
    }));
  }

  toggleDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen;
    this.lockScrollWhileOpen();
  }

  lockScrollWhileOpen() {
    // We save and restore the scroll position when either the
    // drawer opens / closes. Otherwise the page will
    // jump to top when open, caused by `overflow: hidden` - applied
    // to drawer-frame-root for disabling scrolling while one
    // of the two floating elements is open.
    if (this.drawerFrameRootEl) {
      const floatingElements = [this.isDrawerOpen];

      if (!this.pageY) {
        this.pageY = window.pageYOffset;
      }

      if (floatingElements.some((el) => el)) {
        this.drawerFrameRootEl.classList.add('scroll-disabled');
        this.elementsContainerEl.style.top = -(this.pageY) + 'px';
      }

      if (floatingElements.every((el) => !el)) {
        this.drawerFrameRootEl.classList.remove('scroll-disabled');
        this.elementsContainerEl.style.top = '0px';
        window.scrollTo(0, this.pageY);
        this.pageY = 0;
      }
    }
  }

  signOut() {
    Session.delete();
    window.location.href = '/';
  }

  setCookieAccepted() {
    this.cookiePolicyEl.acceptAll();
  }

  initHotReloading() {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '/reload/reload.js';
    head.appendChild(script);
  }

  div: HTMLElement | undefined;

  showLinkedinFollowIfNecessary() {
    const following = Preferences('app').followsLinkedinPage;

    if (!following && !this.div && this.member) {
      this.div = document.createElement('div');
      this.div.className = 'ln-follow-btn';
      this.div.style.backgroundColor = '#50f899';
      this.div.style.padding = '10px';
      this.div.style.display = 'flex';
      this.div.style.justifyContent = 'center';
      this.div.style.alignItems = 'center';

      const s = document.createElement('script');
      s.type = 'IN/FollowCompany';
      s.dataset.id = '5384198';
      this.div.appendChild(s);

      const h4 = document.createElement('h4');
      h4.innerText = 'Please follow us on LinkedIn';
      h4.style.margin = '0px 0px 0px 10px';
      h4.style.fontWeight = '500';
      h4.style.color = '#052d5a';
      this.div.appendChild(h4);

      const s2 = document.createElement('script');
      s2.src = 'https://platform.linkedin.com/in.js';
      s2.type = 'text/javascript';
      s2.innerHTML = ' lang: en_US';
      document.head.appendChild(s2);

      this.div.style.height = '0px';
      this.div.style.overflow = 'hidden';
      this.div.style.padding = '0px';
      document.body.prepend(this.div);

      const callback = (mutationsList: any) => {
        for (const m of mutationsList) {
          if (m.target.offsetWidth > 80) {
            Preferences('app').followsLinkedinPage = true;
            this.div?.remove();
          } else if (this.div) {
            this.div.style.height = 'initial';
            this.div.style.overflow = 'visible';
            this.div.style.padding = '10px';
          }
        }
      };

      const observer = new MutationObserver(callback);

      const i = setInterval(() => {
        const el = document.querySelector('.IN-widget') as HTMLElement;
        if (el) {
          observer.observe(el, {attributes: true, subtree: true});
          clearInterval(i);
        }
      }, 50);

      //Hack for detecting click on cross domain iframe
      window.addEventListener('blur', () => {
        window.setTimeout(() => {
          if (document.activeElement?.tagName === 'IFRAME') {
            observer.observe(document.activeElement, {attributes: true});
          }
        }, 0);
      });
    }
  }

  handleProfileImageError(e: any) {
    e.target.src = this.imgPlaceholder;
  }
}
window.customElements.define('fch-client', FchClient);